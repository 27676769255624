import { useSessionStorageState } from 'ahooks'

export default function useDb() {
  const [ data, dispatch ] = useSessionStorageState<any>('db:states')

  return {
    ...data,
    dispatch: (name: string, value: any) => dispatch({ ...data, [name]: value }),
  }
}
