import http from '@sar/http'
import engine from '@sar/framework'

import React from 'react'
import ReactDOM from 'react-dom/client'

import Root from 'views/root'
import Home from 'views/home'

import Nomatch from 'views/nomatch'
import Solution from 'views/solution'

import Layout from 'plugins/layout'
import Inject from 'plugins/inject'

import db from 'hooks/db'
import config from 'hooks/config'
import scroller from 'hooks/scroller'
import responsive from 'hooks/responsive'

import assets from 'hooks/assets'
import identity from 'hooks/identity'
import encrypt from 'hooks/encrypt'
import externals from 'hooks/externals'

import query from 'middlewares/query'
import author from 'middlewares/author'
import notification from 'middlewares/notification'

const APP = (
  engine
    .create()
    .set((app: engine) => app.usePlugin(Layout))
    .set((app: engine) => app.usePlugin(Inject))
    .set((app: engine) => app.usePrefix(APP_ENV.prefix))
    .set((app: engine) => app.useStore('db', db))
    .set((app: engine) => app.useStore('assets', assets))
    .set((app: engine) => app.useStore('config', config))
    .set((app: engine) => app.useStore('encrypt', encrypt))
    .set((app: engine) => app.useStore('identity', identity))
    .set((app: engine) => app.useStore('externals', externals))
    .set((app: engine) => app.useStore('scroller', scroller))
    .set((app: engine) => app.useStore('responsive', responsive))
    .set((app: engine) => app.useRoute('/', Home))
    .set((app: engine) => app.useRoute('/nomatch',  Nomatch))
    .set((app: engine) => app.useRoute('/solution/:id',  Solution))
    .set((app: engine) => app.useRedirect('/', '/home'))
    .set((app: engine) => app.useRedirect('/*', '/nomatch'))
)

http.setting('base', APP_ENV.endpoint)
http.setting('adapter', APP_ENV.adapter)

http.useProxy('request', author)
http.useProxy('request', query)
http.useProxy('response', notification)

ReactDOM.createRoot(document.getElementById('root')).render(
  <Root>
    <APP.DOM store={APP}/>
  </Root>
)
