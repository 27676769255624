import { useRef } from 'react'
import { useRequest } from 'ahooks'

import { Header } from 'components'
import { Table } from '@sar/component'

export default function ({ store, params, navigator }) {
  const res = useRequest<any, any>(() => store.externals.api.get(`/solution/web/${params.id}`).then(res => JSON.parse(res.data)))

  if (res.data == null) {
    return null
  }

  if (store.responsive.isDesktop) {
    return <main>
      <Header
        store={store}
        navigator={navigator} />

      <img
        alt='banner'
        src={R.head<any>(res.data.banner).url}
        className='h-[50vh] w-full object-cover select-none'
      />

      <section className='p-16 px-32 space-y-8'>
        <h1 className='text-3xl text-gray-800 text-center'>{res.data.name}</h1>
        <p className='text-lg text-gray-700 indent-[2em] leading-loose'>{res.data.summary.trim()}</p>

        <img
          alt='banner'
          className='w-full object-cover'
          src={R.head<any>(res.data.cover).url}
        />

        <ul className={`grid gap-8 ${res.data.problem.length == 1 ? 'grid-cols-1' : res.data.problem.length == 2 ? 'grid-cols-2' : 'grid-cols-3'}`}>
          {res.data.problem.map(elm => {
            return <li
              key={elm.title}
              className='flex flex-col items-center space-y-4'
            >
              <svg className='w-8 h-8 fill-gray-300' viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M838.22 756.41H187.78a89.1 89.1 0 0 1-89.1-89.1V198.54a89.1 89.1 0 0 1 89.1-89.1h650.44a89.1 89.1 0 0 1 89.1 89.1v468.77a89.1 89.1 0 0 1-89.1 89.1zM852 671.35V194.5a9 9 0 0 0-9-8.95H183a9 9 0 0 0-9 8.95v476.85a9 9 0 0 0 9 8.94h660a9 9 0 0 0 9-8.94z"></path><path d="M287.01 832.52m37.86 0l376.26 0q37.86 0 37.86 37.86l0 0.39q0 37.86-37.86 37.86l-376.26 0q-37.86 0-37.86-37.86l0-0.39q0-37.86 37.86-37.86Z"></path><path d="M249.34 337.78m31.68 0l11.97 0q31.68 0 31.68 31.68l0 203.04q0 31.68-31.68 31.68l-11.97 0q-31.68 0-31.68-31.68l0-203.04q0-31.68 31.68-31.68Z"></path><path d="M400 451.95m37.67 0l-0.01 0q37.67 0 37.67 37.67l0 76.89q0 37.67-37.67 37.67l0.01 0q-37.67 0-37.67-37.67l0-76.89q0-37.67 37.67-37.67Z"></path><path d="M550.67 261.67m37.67 0l-0.01 0q37.67 0 37.67 37.67l0 267.17q0 37.67-37.67 37.67l0.01 0q-37.67 0-37.67-37.67l0-267.17q0-37.67 37.67-37.67Z"></path><path d="M701.33 413.89m37.67 0l-0.01 0q37.67 0 37.67 37.67l0 114.94q0 37.67-37.67 37.67l0.01 0q-37.67 0-37.67-37.67l0-114.94q0-37.67 37.67-37.67Z"></path></svg>
              <h3 className='text-lg text-gray-800'>{elm.title}</h3>
              <p className='text-base text-gray-700 leading-loose'>{elm.content}</p>
            </li>
          })}
        </ul>
      </section>

      <section className='px-32 py-16 space-y-16 bg-[#e7f0f7]'>
        <h1 className='text-3xl text-gray-800 text-center'>方案亮点</h1>

        <ul className='grid grid-cols-2 gap-8'>
          {res.data.highlights.map(elm => {
            return <li
              key={elm.title}
              className='space-y-2 p-8 rounded-md bg-white'
            >
              <h3 className='text-lg text-gray-800'>{elm.title}</h3>

              {elm.content.split('\n').filter(R.identity).map(str => {
                return <p className='text-base text-gray-700 leading-loose'>{str}</p>
              })}
            </li>
          })}
        </ul>
      </section>

      <section className='px-32 py-16 space-y-16'>
        <h1 className='text-3xl text-gray-800 text-center'>案例介绍</h1>

        <ul className='space-y-16'>
          {res.data.case.map(elm => {
            return <li key={elm.title} className='space-y-8'>
              <h3 className='text-xl text-gray-800'>{elm.title}</h3>
              <p className='text-lg text-gray-700 leading-loose'>{elm.content}</p>
            </li>
          })}
        </ul>
      </section>
    </main>
  }

  return <main>
    <Header
      store={store}
      navigator={navigator} />

    <img
      alt='banner'
      className='w-full object-cover'
      src={R.head<any>(res.data.banner).url}
    />

    <section className='px-4 py-8 space-y-4'>
      <h3 className='text-xl text-gray-800 text-center'>{res.data.name}</h3>
      <p className='text-lg text-gray-800 indent-[2em] leading-loose'>{res.data.summary.trim()}</p>

      <img
        alt='banner'
        className='w-full object-cover'
        src={R.head<any>(res.data.cover).url}
      />

      <ul className='space-y-6'>
        {res.data.problem.map(elm => {
          return <li key={elm.title} className='space-y-2'>
            <h3 className='text-lg text-gray-800 font-bold'>{elm.title}</h3>
            <p className='text-base text-gray-700 leading-loose'>{elm.content}</p>
          </li>
        })}
      </ul>
    </section>

    <section className='px-4 py-8 space-y-4 bg-[#e7f0f7]'>
      <h3 className='text-xl text-gray-800 text-center'>方案亮点</h3>

      <ul className='space-y-6'>
        {res.data.highlights.map(elm => {
          return <li key={elm.title} className='space-y-2'>
            <h3 className='text-lg text-gray-800 font-bold'>{elm.title}</h3>

            {elm.content.split('\n').filter(R.identity).map(str => {
              return <p className='text-base text-gray-700 leading-loose'>{str}</p>
            })}
          </li>
        })}
      </ul>
    </section>

    <section className='px-4 py-8 space-y-4'>
      <h3 className='text-xl text-gray-800 text-center'>案例介绍</h3>

      <ul className='space-y-6'>
        {res.data.case.map(elm => {
          return <li key={elm.title} className='space-y-2'>
            <h3 className='text-lg text-gray-800 font-bold'>{elm.title}</h3>
            <p className='text-base text-gray-700 leading-loose'>{elm.content}</p>
          </li>
        })}
      </ul>
    </section>
  </main>
}
