import { useSessionStorageState } from 'ahooks'

export default function useIdentity() {
  const [ uid, uidDispatch ] = useSessionStorageState<string>('db:uid')
  const [ user, userDispatch ] = useSessionStorageState<any>('db:user')
  const [ token, tokenDispatch ] = useSessionStorageState<string>('db:token')

  const clear = R.compose(
    R.tap(uidDispatch),
    R.tap(userDispatch),
    R.tap(tokenDispatch),
  )

  const dispatch = R.cond([
    [R.equals('uid'), R.always(uidDispatch)],
    [R.equals('user'), R.always(userDispatch)],
    [R.equals('token'), R.always(tokenDispatch)],
  ])

  return {
    uid,
    user,
    token,
    clear: () => clear(undefined),
    isLogined: () => R.compose(R.not, R.isNil)(uid),
    dispatch: (type: string, data: any) => dispatch?.(type)?.(data),
    hasPermission: (code: number) => R.any((permission: number) => code == permission, user?.permissions ?? []),
  }
}
