import { useRef } from 'react'
import { useBoolean } from 'ahooks'

import { Image } from 'components'
import { Table } from '@sar/component'

export default function View ({ store, navigator }){
  const [ expand,  { set } ] = useBoolean(false)

  if(store.responsive.isDesktop) {
    return <header className='bg-white shadow flex relative z-[999]'>
      <div className='flex-none flex items-center justify-between cursor-pointer p-2 px-8'>
        <Image
          alt='logo'
          className='h-12 object-cover'
          src={store.assets.from('/images/logo.svg')}
        />
      </div>

      <ul className='flex-none flex justify-center items-center pl-8 px-2 space-x-16'>
        {store.config.menus.map(elm => {
          return <li
            key={elm.key}
            className='cursor-pointer text-lg text-gray-800 underline-offset-4 decoration-dotted hover:text-blue-800 hover:underline'
            onClick={() => document.getElementById(elm.key) == null ? navigator.to('/') : store.scroller.animateScroll(document.getElementById(elm.key))}
          >
            {elm.title}
          </li>
        })}
      </ul>
    </header>
  }

  return <header className='pt-2 px-2 bg-white fixed shadow inset-x-0 top-0 z-[999]'>
    <div className='pb-2 flex items-center justify-between'>
      <img
        alt='logo'
        className='h-8 object-cover'
        src={store.assets.from('/images/logo.svg')}
      />
      <div
        onClick={() => set(!expand)}
        style={{ fontSize: '1.6rem' }}
        className='mx-4 fa fa-bars transition-all text-blue-800'
      />
    </div>

    <div className='text-base text-gray-600 overflow-hidden transition-all duration-500 ease-in-out' style={{ maxHeight: expand ? 300 : 0 }}>
      <ul className='p-2 px-4 border-t border-gray-200'>
        {store.config.menus.map(elm => {
          return <li
            key={elm.key}
            className='py-2 hover:text-blue-800 active:text-blue-800'
            onClick={() => document.getElementById(elm.key) == null ? navigator.to('/') : [ set(false), store.scroller.animateScroll(document.getElementById(elm.key)) ]}
          >
            {elm.title}
          </li>
        })}
      </ul>
    </div>
  </header>
}
