export default [
  {
    key: 'summary',
    title: '企业简介',
  },
  {
    key: 'solution',
    title: '解决方案',
  },
  {
    key: 'advantage',
    title: '我们的优势',
  },
  {
    key: 'partner',
    title: '合作伙伴',
  }
]
