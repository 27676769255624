import { useStore } from '@sar/framework/hooks'
import { useLocation, useParams, useNavigate } from 'react-router'

export default (Target: React.FC<any>) => function (props: any) {
  let {
      menu
    , app
    , theme
    , refreshConstant } = props

  const store = useStore()
  const location = useLocation()

  const params = useParams()
  const navigate = useNavigate()

  const navigator = React.useMemo(() => {
    return {
      back: (stack = -1) => navigate(stack),
      to: (url) => navigate(url),
      replace: (url) => navigate(url, { replace: true }),
    }
  }, [])

  return <>
    <Target
      app={app}
      store={store}
      theme={theme}
      params={params}
      location={location}
      navigator={navigator}
      refreshConstant={refreshConstant}
    />

    <footer className='text-center text-gray-700 font-serif text-sm px-4 pb-4 md:text-xl md:pb-8'>
      ©2024 骤雨湾技术服务有限公司（鄂ICP备16018522号-1） All rights reserved.
    </footer>
  </>
}
