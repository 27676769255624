export default function (request) {
  const getQueryData = R.compose(
    R.fromPairs,
    R.map<any, any>(
      R.split('=')
    ),
    R.split('&'),
    R.defaultTo(''),
    R.last,
    R.split('?'),
  )

  if (request.rest.includes('?') == false) {
    return Object.assign(request, {})
  }

  else {
    return Object.assign(request, { rest: request.rest.split('?').shift(), params: Object.assign({}, request.params, getQueryData(request.rest)) })
  }
}
