export default {
  Boolean: [
    {
      name: 'false',
      value: 1,
      description: '否',
    },
    {
      name: 'true',
      value: 0,
      description: '是',
    },
  ],
}
