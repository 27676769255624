import { App } from 'antd'

export default (Target: React.FC<any>) => function () {
  const app = App.useApp()

  React.useEffect(() => {
    Object.assign(window, {
      modal: {
        warning: params => app.modal.warning(params)
      },
    })
  }, [])

  return <Target app={app} />
}
